@tailwind base;
@tailwind components;
@tailwind utilities;


.main_loader_style {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_loader_style span {
  font-family: 'inter_semibold';
  color: var(--dark);
}

input::placeholder {
  color: var(--placeholderColor) !important;
}

::placeholder {
  color: var(--placeholderColor) !important;

}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}
