:root {
    --primary: #339999;
    --darkprimary: #008080;
    --secondary: #6C7774;
    --secondarydark: #979F9D;
    --secondarylight: #DBD8DB;
    --placeholderColor:#969F9D;
    --dark: #003636;
    --black: #000;
    --light: #f0f7f7;
    --white: #FFF;
}